








































interface Iconfig {
  limit?: number; // 文件上传个数 默认1张
  callback?: any[]; // 文件回显地址 对象数组
  accept?: string; // 文件类型 默认pdf 多个/隔开
  fileName?: string; // 上传文件的字段名字,默认时file
  drag?: boolean; // 是否启用拖拽上传
}
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import SparkMD5 from 'spark-md5';
import Qs from 'qs';
import { FileDownload, FileUpload, AdminCheckMd5, AdminUploadBigFile } from '@/http/api/FileApi';

@Component
export default class UploadFile extends Vue {
  @Prop() private config!: Iconfig;
  @Prop({ default: false }) private listClean!: boolean;
  private hideUploadEdit: boolean = false;
  private limit: number = 1;
  private num: any = '';
  private callback: string = '';
  private accept: string = 'pdf';
  private fileName: string = 'file';
  private drag: boolean = false;
  private fileList: any[] = [];
  private fileArr: any[] = [];
  private progressShow: boolean = false; // 是否显示进度条
  private progress: number = 0; // 上传进度
  @Watch('config', {
    immediate: true,
    deep: true,
  })

  private wacthConfig(n: Iconfig, o: Iconfig) {
    // console.log(n, o)
    if (n.limit !== this.limit && n.limit) {
      this.limit = n.limit;
    }
    if (n.accept) {
      this.accept = n.accept;
    }
    if (n.fileName) {
      this.fileName = n.fileName;
    }
    if (n.callback?.length) {
      // console.log(n.callback);

      const callbackArr: string[] = [];
      const fileArr: any[] = [];
      n.callback.forEach((val: any, ind: number) => {
        callbackArr.push(val.path);
        fileArr.push({
          remark: val.remark,
          name: val.remark,
          path: val.path,
        });
      });
      this.callback = callbackArr.join(',');
      this.fileArr = fileArr;
      this.fileList = this.fileArr;
      // console.log(this.fileArr)
    }
  }
  @Watch('fileArr')
  private watchFileArr(n: any, o: any) {
    const arr: any = [];
    for (const item of n) {
      arr.push(item.path);
    }
    this.$emit('UploadFileFun', arr.join(','));
  }
  @Watch('listClean')
  private WatchListCleann() {
    this.fileArr = [];
  }
  private created() {
    if (this.config.limit !== this.limit && this.config.limit) {
      this.limit = this.config.limit;
    }
    if (this.config.accept) {
      this.accept = this.config.accept;
    }
    if (this.config.fileName) {
      this.fileName = this.config.fileName;
    }
    if (this.config.callback?.length) {
      const callbackArr: string[] = [];
      const fileArr: any[] = [];
      this.config.callback.forEach((val: any, ind: number) => {
        callbackArr.push(val.path);
        fileArr.push({
          remark: val.remark,
          path: val.path,
        });
      });
      this.callback = callbackArr.join(',');
      this.fileArr = fileArr;
    }
    // console.log(this.config)
    // console.log(this.callback)
  }
  // 删除文件
  // handleRemove(file, fileList) {
  //     for (let i = 0; i < this.fileArr.length; i++) {
  //       if (file.path == this.fileArr[i].path) {
  //         this.fileArr.splice(i, 1)
  //       }
  //     }
  //     this.fileList = fileList
  //   }
  private handleRemove(file: any, fileList: any) {
    // this.fileArr.splice(index, 1);
    // this.fileList.splice(index, 1);
    for (let i = 0; i < this.fileArr.length; i++) {
      if (file.path === this.fileArr[i].path) {
        this.fileArr.splice(i, 1);
      }
    }
    this.fileList = fileList;
  }
  private handleChange(file: any, fileList: any) {
    this.fileList = fileList
  }
  // 下载
  private handlePreview(file: any) {
    const params = file.path
    // const params = this.fileArr[index].path;
    // console.log(params)
    FileDownload(params);
  }
  // 上传文件
  private AdminUploadFileUp(f: any) {
    const that = this;
    var fileMd5: any = '';
    const file: any = f.file;
    if (!rulesSuffix(file.name, that.accept)) {
      this.$message.warning(`请上传后缀为 ${that.accept} 的文件。`);
      this.fileList.splice(this.fileList.length - 1, 1)
      return;
    }
    const blobSlice = File.prototype.slice
    // 判断文件大小
    if (file.size < 1024 * 1024 * 5) {
      // 小于5M(5242880) 直接用小文件上传接口
      const formdata = new FormData();
      formdata.append('file', f.file);
      // const onUploadProgress = (progressEvent: any) => {
      //   this.progressShow = true;
      //   const progress = (progressEvent.loaded / progressEvent.total * 100) || 0;
      //   this.progress = progress;
      //   if (progress >= 100) {
      //     setTimeout(() => {
      //       this.progressShow = false;
      //       this.progress = 0;
      //     }, 500);
      //   }
      // };
      const onUploadProgress = (progressEvent: any) => {
        const num = (progressEvent.loaded / progressEvent.total * 100)
        this.num = num
        f.onProgress({ percent: num })
      }
      this.progressShow = true;
      FileUpload(formdata, onUploadProgress).then((res) => {
        if (res.code === '000') {
          this.$message.success('上传成功')
          const list = res.result.filesMap.file;
          this.fileList[this.fileList.length - 1].path = res.result.filesMap.file[0].path
          this.fileArr.push(res.result.filesMap.file[0])
          if (this.num == 100) {
            f.onSuccess()
          }
        }
      }).catch(() => {
        console.log(1111)
      })
    } else {
      // 短点续传
      f.onProgress({ percent: 0 })
      calculteFileMd5();
    }
    // 把文件切片，并且把每一片大小用MD5加密
    function calculteFileMd5() {
      const chunkSize = 1024 * 1024 * 3; // Read in chunks of 2MB 。每一块的大小
      const chunks = Math.ceil(file.size / chunkSize); // 整个文件可分为多少块，向下取整
      let currentChunk = 0; // 当前加载的块。初始化为0
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      loadNext();
      fileReader.onload = (e: any) => {
        spark.append(e.target.result);
        currentChunk++;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          fileMd5 = spark.end()
          requestCheckFile();
        }
      };
      function loadNext() {
        const start = currentChunk * chunkSize;
        const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
    }
    
    // 服务器检查文件是否存在
    function requestCheckFile() {
      AdminCheckMd5(Qs.stringify({
        md5: fileMd5,
        name: f.file.name,
        totalBytes: f.file.size,
      })).then(res => {
        if (res.code !== '000') {
          that.fileList.splice(that.fileList.length - 1, 1)
          that.hideUploadEdit = that.fileList.length >= that.limit
        } else {
          if (!res.result.complete) {
            requestRealUpload(res.result)
          } else {
            that.fileList[that.fileList.length - 1].path = res.result.uploadVo.filesMap.file[0].path
            that.fileArr.push(res.result.uploadVo.filesMap.file[0])
            f.onProgress({ percent: 100 })
            setTimeout(() => {
              that.$message.success('上传成功')
              f.onSuccess()
            })
          }
        }
      }).catch((err) => {
        that.$message.error('上传失败');
        that.fileList.splice(that.fileList.length - 1, 1)
        that.hideUploadEdit = that.fileList.length >= that.limit
      });
    }
    // 分块上传
    function requestRealUpload (params: any) {
      var chunkSize = 1024 * 1024 * 3
      uploadChunk(params.uploadedBytes)
      // 请求服务端，上传一块
      function uploadChunk (uploadedBytes: any) {
        var formData = new FormData()
        var start = uploadedBytes
        var end = Math.min(Number(start) + chunkSize, f.file.size)
        formData.append('upfile', blobSlice.call(file, start, end))
        formData.append('md5', fileMd5)
        const onUploadProgress = (progressEvent: any) => {
          // const num = (progressEvent.loaded / progressEvent.total * 100)
          const num = (end / f.file.size * 100)
          console.log(num)
          that.num = num
          f.onProgress({ percent: num })
        }
        AdminUploadBigFile(formData, onUploadProgress).then(res => {
          if (res.code !== '000') {
            that.fileList.splice(that.fileList.length - 1, 1)
            that.hideUploadEdit = that.fileList.length >= that.limit
          } else {
            if (res.result < f.file.size) {
              uploadChunk(res.result)
            } else {
              that.fileList[that.fileList.length - 1].path = res.result.filesMap.file[0].path
              that.fileArr.push(res.result.filesMap.file[0])
              if (that.num == 100) {
                that.$message.success('上传成功')
                f.onSuccess()
              }
            }
          }
        }).catch(() => {
          that.$message.error('上传失败')
          that.fileList.splice(that.fileList.length - 1, 1)
          that.hideUploadEdit = that.fileList.length >= that.limit
        })
      }
    }
    // 检查文件后缀
    function rulesSuffix(fileName: string, acceptSuffix: string): boolean {
      // const fileSuffix: string = fileName.split('.')[1];
      const fileSuffix: string = fileName.substring(fileName.lastIndexOf('.') + 1);
      const acceptArr: string[] = acceptSuffix.split('/');
      // console.log(fileName)
      // console.log(fileSuffix)
      // console.log(acceptArr)
      let num: number = 0;
      acceptArr.forEach((val: string, ind: number) => {
        if (fileSuffix === val) {
          num++;
        }
      });
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    }
    
    // var fileMd5
    // if (file.size > 1024 * 1024 * 5) {
    //   f.onProgress({ percent: 0 })
    //   calculteFileMd5()
    // } else {
    //   const formdata = new FormData()
    //   formdata.append('file', f.file)
    //   const ff = {
    //     onUploadProgress: progressEvent => {
    //       const num = (progressEvent.loaded / progressEvent.total * 100)
    //       this.num = num
    //       f.onProgress({ percent: num })
    //     },
    //     timeout: 0
    //   }
    //   AdminUploadFile(formdata, ff).then(res => {
    //     if (res.code === '000') {
    //       this.$message.success('上传成功')
    //       this.fileList[this.fileList.length - 1].path = res.result.filesMap.file[0].path
    //       this.fileArr.push(res.result.filesMap.file[0])
    //       if (this.num == 100) {
    //         f.onSuccess()
    //       }
    //     } else {
    //       this.$message.error(res.msg)
    //       this.fileList.splice(this.fileList.length - 1, 1)
    //       this.hideUploadEdit = this.fileList.length >= this.limit
    //     }
    //   }).catch(() => {
    //     this.fileList.splice(this.fileList.length - 1, 1)
    //     this.hideUploadEdit = this.fileList.length >= this.limit
    //     this.$message.error('上传失败')
    //   })
    // }
    // function calculteFileMd5 () {
    //   const chunkSize = 1024 * 1024 * 3 // Read in chunks of 2MB 。每一块的大小
    //   const chunks = Math.ceil(file.size / chunkSize) // 整个文件可分为多少块，向下取整
    //   var currentChunk = 0 // 当前加载的块。初始化为0
    //   const spark = new SparkMD5.ArrayBuffer()
    //   const fileReader = new FileReader()

    //   fileReader.onload = e => {
    //     spark.append(e.target.result)
    //     currentChunk++
    //     if (currentChunk < chunks) {
    //       loadNext()
    //     } else {
    //       // 计算出文件的md5值
    //       fileMd5 = spark.end()
    //       // 服务器检查文件是否存在
    //       requestCheckFile()
    //     }
    //   }
    //   loadNext()
    //   function loadNext () {
    //     var start = currentChunk * chunkSize
    //     var end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
    //     fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    //   }
    // }
    // 请求服务器验证文件
    // function requestCheckFile () {
    //   AdminCheckMd5(Qs.stringify({ md5: fileMd5, name: f.file.name, totalBytes: f.file.size })).then(res => {
    //     // console.log(res)
    //     if (res.code !== '000') {
    //       that.$message.error(res.msg)
    //       that.fileList.splice(that.fileList.length - 1, 1)
    //       that.hideUploadEdit = that.fileList.length >= that.limit
    //     } else {
    //       if (!res.result.complete) {
    //         requestRealUpload(res.result)
    //       } else {
    //         that.fileList[that.fileList.length - 1].path = res.result.uploadVo.filesMap.file[0].path
    //         that.fileArr.push(res.result.uploadVo.filesMap.file[0])
    //         f.onProgress({ percent: 100 })
    //         setTimeout(() => {
    //           that.$message.success('上传成功')
    //           f.onSuccess()
    //         })
    //       }
    //     }
    //   }).catch(() => {
    //     that.$message.error('上传失败')
    //     that.fileList.splice(that.fileList.length - 1, 1)
    //     that.hideUploadEdit = that.fileList.length >= that.limit
    //   })
    // }
    // 分块上传
    // function requestRealUpload (params) {
    //   var chunkSize = 1024 * 1024 * 3
    //   uploadChunk(params.uploadedBytes)
    //   // 请求服务端，上传一块
    //   function uploadChunk (uploadedBytes) {
    //     var formData = new FormData()
    //     var start = uploadedBytes
    //     var end = Math.min(Number(start) + chunkSize, f.file.size)
    //     formData.append('upfile', blobSlice.call(file, start, end))
    //     formData.append('md5', fileMd5)
    //     const ff = {
    //       onUploadProgress: progressEvent => {
    //         // console.log(progressEvent)
    //         // const num = (progressEvent.loaded / progressEvent.total * 100)
    //         const num = (end / f.file.size * 100)
    //         that.num = num
    //         f.onProgress({ percent: num })
    //       },
    //       timeout: 0
    //     }
    //     AdminUploadBigFile(formData, ff).then(res => {
    //       if (res.code !== '000') {
    //         that.$message.error(res.msg)
    //         that.fileList.splice(that.fileList.length - 1, 1)
    //         that.hideUploadEdit = that.fileList.length >= that.limit
    //       } else {
    //         if (res.result < f.file.size) {
    //           uploadChunk(res.result)
    //         } else {
    //           that.fileList[that.fileList.length - 1].path = res.result.filesMap.file[0].path
    //           that.fileArr.push(res.result.filesMap.file[0])
    //           if (that.num == 100) {
    //             that.$message.success('上传成功')
    //             f.onSuccess()
    //           }
    //         }
    //       }
    //     }).catch(() => {
    //       that.$message.error('上传失败')
    //       that.fileList.splice(that.fileList.length - 1, 1)
    //       that.hideUploadEdit = that.fileList.length >= that.limit
    //     })
    //   }
    // }
  }
  private handleExceed(file: any, fileList: any) {
    console.log(222)
    this.$message.warning(`最多上传 ${this.limit} 个文件。`)
  }
}
/*
import SparkMD5 from 'spark-md5'
import Qs from 'qs'
// AdminUploadFile
import { FileDownload, FileUpload, AdminCheckMd5, AdminUploadBigFile } from '@/http/File'
export default {
  props: ['limit', 'callbackShow', 'accept'],
  data () {
    return {
      num: '',
      info: {},
      fileList: [],
      fileArr: []
    }
  },
  watch: {
    fileArr (newValue, oldValue) {
      const arr = []
      for (let i = 0; i < newValue.length; i++) {
        arr.push(newValue[i].path)
      }
      this.$emit('UploadFileFun', arr.join(','))
    }
  },
  created () {
    if (this.callbackShow && this.callbackShow.length > 0) {
      this.callbackShow.forEach(element => {
        element.name = element.remark
      })
      this.fileList = this.callbackShow
      this.fileArr = this.callbackShow
    }
  },
  methods: {
    // 超出文件个数限制提示
    handleExceed() {
      this.$message.warning(`最多上传 ${this.limit} 个文件。`)
    },
    // 下载
    handlePreview (file) {
      const params = { path: file.path }
      FileDownload(params)
      // AdminDownLoadFile(params).then(res => {
      //   const blob = new Blob([res.data])
      //   const fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
      //   if ('download' in document.createElement('a')) { // 非IE下载
      //     const elink = document.createElement('a')
      //     elink.download = fileName
      //     elink.style.display = 'none'
      //     elink.href = URL.createObjectURL(blob)
      //     document.body.appendChild(elink)
      //     elink.click()
      //     URL.revokeObjectURL(elink.href) // 释放URL 对象
      //     document.body.removeChild(elink)
      //   } else { // IE10+下载
      //     navigator.msSaveBlob(blob, fileName)
      //   }
      // })
    },
    // 上传成功
    handleChange (file, fileList) {
      this.fileList = fileList
    },
    // 删除文件
    handleRemove(file, fileList) {
      for (let i = 0; i < this.fileArr.length; i++) {
        if (file.path == this.fileArr[i].path) {
          this.fileArr.splice(i, 1)
        }
      }
      this.fileList = fileList
    },
    AdminUploadFileUp (f) {
      const file = f.file
      var that = this
      var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      var fileMd5
      if (file.size > 1024 * 1024 * 5) {
        f.onProgress({ percent: 0 })
        calculteFileMd5()
      } else {
        const formdata = new FormData()
        formdata.append('file', f.file)
        const ff = {
          onUploadProgress: progressEvent => {
            const num = (progressEvent.loaded / progressEvent.total * 100)
            this.num = num
            f.onProgress({ percent: num })
          },
          timeout: 0
        }
        AdminUploadFile(formdata, ff).then(res => {
          if (res.code === '000') {
            this.$message.success('上传成功')
            this.fileList[this.fileList.length - 1].path = res.result.filesMap.file[0].path
            this.fileArr.push(res.result.filesMap.file[0])
            if (this.num == 100) {
              f.onSuccess()
            }
          } else {
            this.$message.error(res.msg)
            this.fileList.splice(this.fileList.length - 1, 1)
            this.hideUploadEdit = this.fileList.length >= this.limit
          }
        }).catch(() => {
          this.fileList.splice(this.fileList.length - 1, 1)
          this.hideUploadEdit = this.fileList.length >= this.limit
          this.$message.error('上传失败')
        })
      }
      function calculteFileMd5 () {
        const chunkSize = 1024 * 1024 * 3 // Read in chunks of 2MB 。每一块的大小
        const chunks = Math.ceil(file.size / chunkSize) // 整个文件可分为多少块，向下取整
        var currentChunk = 0 // 当前加载的块。初始化为0
        const spark = new SparkMD5.ArrayBuffer()
        const fileReader = new FileReader()

        fileReader.onload = e => {
          spark.append(e.target.result)
          currentChunk++
          if (currentChunk < chunks) {
            loadNext()
          } else {
            // 计算出文件的md5值
            fileMd5 = spark.end()
            // 服务器检查文件是否存在
            requestCheckFile()
          }
        }
        loadNext()
        function loadNext () {
          var start = currentChunk * chunkSize
          var end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
        }
      }
      // 请求服务器验证文件
      function requestCheckFile () {
        AdminCheckMd5(Qs.stringify({ md5: fileMd5, name: f.file.name, totalBytes: f.file.size })).then(res => {
          // console.log(res)
          if (res.code !== '000') {
            that.$message.error(res.msg)
            that.fileList.splice(that.fileList.length - 1, 1)
            that.hideUploadEdit = that.fileList.length >= that.limit
          } else {
            if (!res.result.complete) {
              requestRealUpload(res.result)
            } else {
              that.fileList[that.fileList.length - 1].path = res.result.uploadVo.filesMap.file[0].path
              that.fileArr.push(res.result.uploadVo.filesMap.file[0])
              f.onProgress({ percent: 100 })
              setTimeout(() => {
                that.$message.success('上传成功')
                f.onSuccess()
              })
            }
          }
        }).catch(() => {
          that.$message.error('上传失败')
          that.fileList.splice(that.fileList.length - 1, 1)
          that.hideUploadEdit = that.fileList.length >= that.limit
        })
      }
      // 分块上传
      function requestRealUpload (params) {
        var chunkSize = 1024 * 1024 * 3
        uploadChunk(params.uploadedBytes)
        // 请求服务端，上传一块
        function uploadChunk (uploadedBytes) {
          var formData = new FormData()
          var start = uploadedBytes
          var end = Math.min(Number(start) + chunkSize, f.file.size)
          formData.append('upfile', blobSlice.call(file, start, end))
          formData.append('md5', fileMd5)
          const ff = {
            onUploadProgress: progressEvent => {
              // console.log(progressEvent)
              // const num = (progressEvent.loaded / progressEvent.total * 100)
              const num = (end / f.file.size * 100)
              that.num = num
              f.onProgress({ percent: num })
            },
            timeout: 0
          }
          AdminUploadBigFile(formData, ff).then(res => {
            if (res.code !== '000') {
              that.$message.error(res.msg)
              that.fileList.splice(that.fileList.length - 1, 1)
              that.hideUploadEdit = that.fileList.length >= that.limit
            } else {
              if (res.result < f.file.size) {
                uploadChunk(res.result)
              } else {
                that.fileList[that.fileList.length - 1].path = res.result.filesMap.file[0].path
                that.fileArr.push(res.result.filesMap.file[0])
                if (that.num == 100) {
                  that.$message.success('上传成功')
                  f.onSuccess()
                }
              }
            }
          }).catch(() => {
            that.$message.error('上传失败')
            that.fileList.splice(that.fileList.length - 1, 1)
            that.hideUploadEdit = that.fileList.length >= that.limit
          })
        }
      }
    }
  }
}
*/
